import React from 'react';
import BaseTemplate from '../../../components/templates/BaseLayout';
import SEO from '../../../components/atoms/utility/seo';
import Spacer from '../../../components/atoms/utility/Spacer';
import EventsListBlogV2 from '../../../components/organisms/EventsListBlogV2';
import { grandRapidsEvents } from '../../../content/events/grand-rapids-events';

function GrandRapidsLiveMusic() {
    return (
        <BaseTemplate>
            <SEO
                title={'Live Music Grand Rapids'}
                description={'Come find Live Music in Grand Rapids!'}
            />
            <Spacer sizeY={1} className="" />
            <EventsListBlogV2
                cityName="Grand Rapids"
                categoryPage={'Live Music'}
                eventsData={grandRapidsEvents}
            />
            <Spacer sizeY={1} className="" />
        </BaseTemplate>
    );
}

export default GrandRapidsLiveMusic;
